<template>
  <section class="footer mb-3 mt-3">
    <div class="body-row row justify-content-center align-items-center">
      <div class="col-md-5 col-12">
        <SkillBadge v-for="(skill, idx) in config.skills" :key="idx" :image="skill.image" :percent="skill.percent" />
      </div>
      <div class="col-md-3 col-12">
        <ul class="list-group">
          <li class="list-group-item"><fa-icon icon="fa-solid fa-at" /> {{ config.contact.email }}</li>
          <li class="list-group-item"><fa-icon icon="fa-solid fa-phone" /> {{ config.contact.phone }}</li>
          <li class="list-group-item"><fa-icon icon="fa-brands fa-whatsapp" /> {{ config.contact.whatsapp }}</li>
        </ul>
      </div>
      <div class="col-md-2 col-6">
        <img :src="'./assets/images/qrcode.jpeg'" :style="{width: '100%', border: 'solid #0000 10px', borderRadius:'0 15% 15%'}"/>
      </div>
    </div>
</section>
</template>

<script>
import SkillBadge from "./SkillBadge.vue"

export default {
  name: 'FooterSection',
  props: {
    config: Object
  },
  components: {
    SkillBadge
  }
}
</script>

<style scoped>
.list-group {
  --bs-list-group-bg: none;
  --bs-list-group-border-width: none;
}
</style>
